<template>
  <div id="subpage-content">
    <InformsDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NavbarAboutUs") }}</h1>
        <!-- <v-card id="grey">
          <div id="abt-bg">
            <div><v-icon x-large>mdi-format-quote-open</v-icon></div>
            <div id="left-pad">{{ $t("message.AboutText") }}</div>
          </div>
          <div id="recom">
            <div id="file">
              <v-icon x-large>mdi-file</v-icon>
              <div id="file-sub">
                <a
                  href="https://its.ooo/upload/iblock/4cf/4cfc9371453d4b216838f09d200ca2ba.pdf"
                  target="_blank"
                  title="Рекомендательное письмо Лорри"
                  >Рекомендательное письмо Лорри</a
                >
                <a>{{ $t("message.Size") }} 336.9 Kb</a>
              </div>
            </div>
          </div>
        </v-card>
        <div id="file">
          <div id="file-sub-name">
            <a id="bold">{{ $t("message.Bistrov") }}</a>
            <a>{{ $t("message.Direct") }}</a>
          </div>
        </div> -->
        <!--  -->
        <v-col v-for="item in items" :key="item.id" :cols="item.id">
          <div>
            <v-card id="grey">
              <div id="abt-bg">
                <div><v-icon x-large>mdi-format-quote-open</v-icon></div>
                <div id="left-pad">{{ getItemText(item) }}</div>
              </div>
              <div id="recom">
                <div id="file">
                  <v-icon x-large>mdi-file</v-icon>
                  <div id="file-sub">
                    <a v-on:click="downloadFile(item)" id="link" v-bind:title="getItemName(item)">{{ getItemName(item) }}</a>
                    <a>{{ $t("message.Size") + getItemSize(item) }} </a>
                  </div>
                </div>
              </div>
            </v-card>
            <div id="file">
              <div id="file-sub-name">
                <!-- <a id="bold">{{ $t("message.Bistrov") }}</a> -->
                <a>{{ getItemDescription(item) }}</a>
              </div>
            </div>
          </div>
        </v-col>
        <!--  -->
      </v-card>
    </div>
  </div>
</template>

<script>
import InformsDrawer from "../../../views/Navigations/Informs/InformsDrawer";
export default {
  components: {
    InformsDrawer,
  },
  data: () => ({
    items: [],
  }),
  created() {
    window.addEventListener("beforeunload", this.handler());
  },
  async mounted() {
    await this.getArticleblock();
  },

  methods: {
    async handler() {
      await this.$store.dispatch("getAllArticleblocks");
      this.items = await this.$store.getters.getArticleblocks.filter((item) => item.isclientsref);
    },

    async getArticleblock() {
      // await this.$store.dispatch("getAllArticleblocks");
      this.items = await this.$store.getters.getArticleblocks.filter((item) => item.isclientsref);
    },
    getItemName(item) {
      return item.name + ".pdf";
    },
    getFileUrl(item) {
      // let link = document.createElement("a");
      // link.download = "Info.pdf";
      // let blob = new Blob([item.file], { type: "data:application/pdf;base64," });
      // link.href = URL.createObjectURL(blob);
      // console.log(link);
      // // link.click();
      // URL.revokeObjectURL(link.href);
      const linkSource = `data:application/pdf;base64,${item.file}`;
      const downloadLink = document.createElement("a");
      const fileName = this.getItemName(item);
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      return "";
    },
    downloadFile(item) {
      this.getFileUrl(item);
    },
    getItemSize(item) {
      return item.size;
    },
    getItemText(item) {
      if (this.$i18n.locale === "ru") {
        return item.text1;
      } else if (this.$i18n.locale === "gb") {
        return item.text2;
      }
    },
    getItemDescription(item) {
      if (this.$i18n.locale === "ru") {
        return item.description1;
      } else if (this.$i18n.locale === "gb") {
        return item.description2;
      }
    },
  },
};
</script>
<style>
#grey {
  background-color: rgb(235, 234, 243);
  margin-top: 20px;
  font: 13px/20px Ubuntu, Arial, sans-serif;
}
#abt-bg {
  display: flex;
  padding-top: 20px;
  margin: 10px;
}
#left-pad {
  padding-left: 15px;
}
#file {
  margin: 10px;
  display: flex;
  font: 13px/20px Ubuntu, Arial, sans-serif;
}
#file-sub {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
#file-sub-name {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  background-color: rgb(255, 255, 255);
}
#bold {
  font-weight: bold;
}
#link {
  color: #5490e6;

  cursor: hand;
}
#link:hover {
  text-decoration-line: underline;
}
</style>

<template>
  <div id="navcard">
    <v-card height="auto" width="256" id="drawer">
      <v-navigation-drawer permanent>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title id="drawer-title">
              {{ $t("message.InformsTitle") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav id="text-style">
          <v-list-item
            id="nav-btn"
            v-for="item in items"
            :key="item.title"
            link
            @click="$router.push({ path: item.route })"
          >
            <v-list-item-content>
              <v-list-item-title class="text-h7">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <div id="prom-text">
      {{ $t("message.PromText") }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "message.NavbarNews", route: "/informs/news" },
        { title: "message.NavbarArticle", route: "/informs/article" },
        { title: "message.NavbarPresentations", route: "/informs/present" },
        { title: "message.NavbarAboutUs", route: "/company/aboutus" },
        { title: "message.NavbarAsk", route: "/informs/ask" },
      ],
    };
  },
};
</script>

<style>
#drawer {
  display: flex;
}
#drawer-title {
  letter-spacing: normal;
  font-size: 32px;
  font-family: Ubuntu, Arial, sans-serif;
}
#text-style {
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#nav-btn {
  border: 2px solid;
}
#navcard {
  display: block;
}
#prom-text {
  color: #666666;
  font-style: italic;
  padding-top: 40px;
  margin-left: 3%;
  margin-right: 3%;
  width: 250px;
  font-size: 13px;
  font-family: Ubuntu, Arial, sans-serif;
}
#subpage-content {
  height: 100%;
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 160px;
  display: flex;
}
#rcol {
  margin-left: 5%;
}
#rcol-incard {
  padding: 30px;
}
#about-text {
  padding-top: 20px;
}
/* Для интеграции */

#pad {
  padding-top: 10px;
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#nopad {
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#marg {
  margin-top: 20px;
}
#buy-card {
  display: flex;
  background-color: #e2e2e2;
  margin-top: 50px;
  margin-bottom: 50px;
}
#buy-btn {
  margin: 15px;
  display: flex;
  align-self: center;
  width: 25%;
  font: 14px/20px Ubuntu, Arial, sans-serif;
  color: rgb(247, 247, 247);
  background-color: #00569c;
  letter-spacing: 0.01em;
}
#history-card-text {
  padding: 15px;
  background-color: #eaf0f2;
  width: 75%;
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#naming-pad {
  font: 24px "Open Sans", sans-serif;
  font-weight: bold;
  display: inline;
  border-bottom: 2px solid #00569c;
}
#news-contain {
  padding-top: 20px;
}
#pic {
  margin-right: 15px;
  margin-bottom: 15px;
}
#inf-news-carddate {
  font: 12px "Open Sans", sans-serif;
  border-left: 1px solid #c4c4c4;
  color: #747474;
  padding-left: 8px;
}
#innews {
  display: flex;
  flex-direction: column;
}
#innews-pad {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
#backto {
  padding-top: 20px;
  font: 14px/20px Ubuntu, Arial, sans-serif;
}
#backto:hover {
  padding-top: 20px;
  color: #00569c;
  -webkit-text-shadow: 1px 1px 1px #00569c;
  -moz-text-shadow: 1px 1px 1px #00569c;
  text-shadow: 1px 1px 1px #00569c;
}
@media (max-width: 1220px) {
  #subpage-content {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 220px;
  }
}
@media (max-width: 970px) {
  #navcard {
    display: none;
  }
  #buy-card {
    flex-direction: column;
  }
  #buy-btn {
    width: 50%;
  }
  #history-card-text {
    width: 100%;
  }
}
@media (max-width: 480px) {
  #content-wrapper {
    flex-direction: column;
  }
  #subpage-content {
    padding-top: 240px;
  }
}
@media (max-width: 860px) {
  #subpage-content {
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 180px;
  }
}
</style>